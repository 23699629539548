import { ActiveUserSubscription, UserSubscription, UserSubscriptionStatus } from '@common/models';
import { createAction, props } from '@ngrx/store';

import { PaymentMethodDTO } from '../../dashboard/billing/models';

export const loadHasProAccess = createAction('[Billing] Load has PRO access');
export const loadHasProAccessSuccess = createAction(
  '[Billing] Load has PRO access success',
  props<{ isProAccessProvided: boolean }>(),
);

export const loadSubscription = createAction('[Billing] Load subscription');
export const loadSubscriptionSuccess = createAction(
  '[Billing] Load subscription success',
  props<{ subscription: UserSubscription }>(),
);

export const loadPaymentMethod = createAction('[Billing] Load payment method');
export const loadPaymentMethodSuccess = createAction(
  '[Billing] Load payment method success',
  props<{ paymentMethod: PaymentMethodDTO }>(),
);
export const loadPaymentMethodError = createAction('[Billing] Load payment method error');

export const loadActiveSubscriptions = createAction('[Billing] Load active subscriptions');
export const loadActiveSubscriptionsSuccess = createAction(
  '[Billing] Load active subscriptions success',
  props<{ subscriptions: ActiveUserSubscription[] }>(),
);

export const setSubscriptionStatus = createAction(
  '[Billing] Set subscription status',
  props<{ subscriptionStatus: UserSubscriptionStatus }>(),
);
export const setSubscriptionResult = createAction('[Billing] Set subscription result', props<{ trial: boolean }>());
